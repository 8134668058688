import styled from 'styled-components';
import React from 'react';
import { mobile, big } from '../../styles/breakpoints';
import { useAnimation, motion } from 'framer-motion';
import { Link } from 'react-router-dom';

export const Box = ({ icon, label, secondaryLabel, image, onClick, url }) => {
  const imgAnimation = useAnimation();

  const handleMouseMove = (e) => {
    const { clientX, clientY } = e;
    const moveX = clientX - window.innerWidth / 2;
    const moveY = clientY - window.innerHeight / 2;
    const offsetFactor = 35;
    imgAnimation.start({
      x: moveX / offsetFactor,
      y: moveY / offsetFactor,
    });
  };

  const textMotion = {
    rest: {
      y: 0,
      transition: {
        duration: 0.6,
        type: 'tween',
        ease: 'easeIn',
      },
    },
    hover: {
      y: -56,
      transition: {
        duration: 0.8,
        type: 'tween',
        ease: 'easeOut',
      },
    },
  };

  const textMotion2 = {
    rest: {
      y: 56,
      transition: {
        duration: 0.6,
        type: 'tween',
        ease: 'easeIn',
      },
    },
    hover: {
      y: 0,
      transition: {
        duration: 0.8,
        type: 'tween',
        ease: 'easeOut',
      },
    },
  };

  const overlayMotion = {
    rest: {
      opacity: 1,
      transition: {
        duration: 0.6,
        type: 'tween',
        ease: 'easeIn',
      },
    },
    hover: {
      opacity: 0,
      transition: {
        duration: 0.6,
        type: 'tween',
        ease: 'easeOut',
      },
    },
  };
  return (
    <>
      <Wrapper
        onClick={onClick}
        as={motion.div}
        initial="rest"
        whileHover="hover"
        animate="rest"
      >
        <Link to={url}>
          <BoxBackgound
            as={motion.img}
            animate={imgAnimation}
            onMouseMove={(e) => handleMouseMove(e)}
            src={image}
            alt="Woman in a red dress"
          ></BoxBackgound>
          <BoxOverlay variants={overlayMotion} />
          <TextBoxWrapper
            as={motion.div}
            initial={{ opacity: 0, y: 40, x: 0, skewX: 0, skewY: 3 }}
            animate={{ opacity: 1, y: 0, x: 0, skewX: 0, skewY: 0 }}
            transition={{ ease: 'easeOut', duration: 2, delay: 1 }}
          >
            <Text variants={textMotion}>{label}</Text>
            <Text variants={textMotion2}>{secondaryLabel}</Text>
            <BoxIcon src={icon} />
          </TextBoxWrapper>
        </Link>
      </Wrapper>
    </>
  );
};

const BoxIcon = styled.img`
  height: 1rem;
  position: relative;
  z-index: 5;
`;
const BoxOverlay = styled(motion.div)`
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  z-index: 1;
  width: 100%;
  height: 100%;
  background-color: #0f0f0f;
  pointer-events: none;
`;

const TextBoxWrapper = styled.div`
  position: absolute;
  bottom: 2rem;
  left: 0;
  display: flex;
  z-index: 5;
  width: 100%;
  height: 56px;
  overflow: hidden;
  padding: 0 2.5rem;
  align-items: baseline;
  justify-content: space-between;
  @media ${mobile} {
    padding: 0 24px;
    bottom: 1rem;
  }
`;
const Text = styled(motion.span)`
  color: #fff;
  font-weight: 400;
  font-family: 'Raleway', sans-serif;
  font-size: 2rem;
  line-height: 3.5rem;
  position: relative;
  &:nth-child(2) {
    position: absolute;
  }
  @media ${mobile} {
    font-size: 1.25rem;
  }
  @media ${big} {
    font-size: 2.5rem;
  }
`;
const BoxBackgound = styled.img`
  margin-top: -5%;
  margin-left: -5%;
  width: 140%;
  height: 140%;
  object-fit: cover;

  @media ${mobile} {
    padding: 0;
  }
`;
const Wrapper = styled.div`
  width: 100%;
  display: flex;
  flex: 1 0 50%;
  position: relative;
  overflow: hidden;
  height: 50%;

  &:nth-child(1) {
    border-bottom: 1px solid #3c3c3c;
    border-right: 1px solid #3c3c3c;
  }
  &:nth-child(2) {
    border-bottom: 1px solid #3c3c3c;
  }
  &:nth-child(3) {
    border-right: 1px solid #3c3c3c;
  }
  @media ${mobile} {
    height: 210px;
  }
`;
