import styled from "styled-components";
import { white, primary } from "../../styles/colors";

export const H6 = styled.h6`
  padding: ${(props) => props.padding || "0"};
  margin: ${(props) => props.margin || "0"};
  font-weight: ${(props) => props.fontWeight || 400};
  max-width: ${(props) => props.maxWidth || "100%"};
  font-size: ${(props) => props.fontSize || "28px"};
  line-height: ${(props) => props.lineHeight || "1.2"};
  display: flex;
  color: ${(props) => props.color || white};
  strong {
    font-weight: 800;
    color: ${primary};
  }
`;
