export const textTransition = {
  visible: {
    opacity: 1,
    y: 0,
    x: 0,
    skewX: 0,
    skewY: 0,
    transition: { duration: 1 },
  },
  hidden: { opacity: 0, y: 40, x: 0, skewX: 0, skewY: 0 },
};
