import styled from "styled-components";
import React from "react";
import PropTypes from "prop-types";
import { H1 } from "../../components";
import { mobile } from "../../styles/breakpoints";
import { motion } from "framer-motion";

export const PageTitle = ({ title, strong, className }) => {
  return (
    <Wrapper className={className}>
      <H1
        as={motion.h1}
        initial={{ opacity: 0, y: 40, x: 0, skewX: 0, skewY: 3 }}
        animate={{ opacity: 1, y: 0, x: 0, skewX: 0, skewY: 0 }}
        transition={{ ease: "easeOut", duration: 2, delay: 0.3 }}
      >
        {title} <br />
        <strong>{strong}</strong>
      </H1>
    </Wrapper>
  );
};

PageTitle.propTypes = {
  type: PropTypes.string,
  className: PropTypes.string,
  label: PropTypes.string,
  onClick: PropTypes.func,
  disabled: PropTypes.bool,
  icon: PropTypes.string,
};

PageTitle.defaultProps = {
  type: "button",
  disabled: false,
};
const Wrapper = styled.div`
  width: 100%;
  display: flex;
  background: #0f0f0f;
  padding: 45vh 5% 100px;

  &.border-bottom {
    border-bottom: 1px solid #3c3c3c;
  }

  @media ${mobile} {
    padding: 30vh 32px 4rem;
  }
`;
