import styled from 'styled-components';
import React from 'react';
import PropTypes from 'prop-types';
import { mobile } from '../../styles/breakpoints';

export const PageImage = ({ image }) => {
  return (
    <Wrapper>
      <Image src={image} />
    </Wrapper>
  );
};

PageImage.propTypes = {
  type: PropTypes.string,
  className: PropTypes.string,
  label: PropTypes.string,
  onClick: PropTypes.func,
  disabled: PropTypes.bool,
  icon: PropTypes.string,
};

PageImage.defaultProps = {
  type: 'button',
  disabled: false,
};
const Wrapper = styled.div`
  height: 100vh;
  width: 100%;
  @media ${mobile} {
    height: initial;
    width: 100%;
    background: #0f0f0f;
    overflow: hidden;
  }
`;
const Image = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
  @media ${mobile} {
    height: 55vh;
    width: initial;
  }
`;
