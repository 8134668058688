export const black = "#201E1F";
export const primary = "#4258CF";
export const secondary = "#55BB87";
export const lightGreen = "#E1FFF0";
export const lightBlue = "#DBE1FF";
export const orange = "#FFB43F";
export const grey = "#ebebeb";
export const white = "#FFFFFF";
export const border = "#EAEAEA";
export const darkGrey = "#d4d4d4";
