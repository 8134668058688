import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import { mobile, big } from "../../styles/breakpoints";
import { icons } from "../../utils/icons";
import { H1, Paragraph, PrimaryButton, Box } from "../../components";
import { images } from "../../utils/images";
import { motion } from "framer-motion";

export const Hero = () => {
  return (
    <Wrapper>
      <Split>
        <TextWrapper>
          <H1
            as={motion.h1}
            initial={{ opacity: 0, y: 40, x: 0, skewX: 0, skewY: 0 }}
            animate={{ opacity: 1, y: 0, x: 0, skewX: 0, skewY: 0 }}
            transition={{ ease: "easeOut", duration: 2, delay: 0.3 }}
          >
            Odontologijos<strong> klinikų konsultantas</strong>
          </H1>
          <Paragraph
            as={motion.p}
            initial={{ opacity: 0, y: 40, x: 0, skewX: 0, skewY: 0 }}
            animate={{ opacity: 1, y: 0, x: 0, skewX: 0, skewY: 0 }}
            transition={{ ease: "easeOut", duration: 2, delay: 0.5 }}
          >
            Gydytojams odontologams reikia tų, kurie juos supranta, bet
            labiausiai - jiems reikia sprendimų.
          </Paragraph>
          <ButtonsWrapper
            as={motion.div}
            initial={{ opacity: 0, y: 40, x: 0, skewX: 0, skewY: 0 }}
            animate={{ opacity: 1, y: 0, x: 0, skewX: 0, skewY: 0 }}
            transition={{ ease: "easeOut", duration: 2, delay: 0.7 }}
          >
            <PrimaryButton
              url="/services"
              label="Paslaugos"
              icon={icons.rightarrowcircle}
            ></PrimaryButton>
          </ButtonsWrapper>
        </TextWrapper>
        <BoxWrapper>
          <Box
            label="Apie"
            secondaryLabel="Apie"
            icon={icons.rightarrowcircle}
            image={images.bg2}
            url="/about"
          />

          <Box
            label="Paslaugos"
            secondaryLabel="Paslaugos"
            icon={icons.rightarrowcircle}
            image={images.bg2}
            url="/services"
          />
          <Box
            label="Konsultacija"
            secondaryLabel="Konsultacija"
            icon={icons.rightarrowcircle}
            image={images.bg2}
            url="mailto:nouhaudental@gmail.com"
          />
          <Box
            label="Kontaktai"
            secondaryLabel="Kontaktai"
            icon={icons.rightarrowcircle}
            image={images.bg2}
            url="/contact"
          />
        </BoxWrapper>
      </Split>
    </Wrapper>
  );
};

Hero.propTypes = {
  label: PropTypes.string,
  imageSource: PropTypes.string,
  price: PropTypes.string,
};

const Wrapper = styled.div`
  width: 100%;
  position: relative;
  height: 100vh;
  display: flex;
  align-items: center;
  overflow: hidden;
  background: #0f0f0f;
  @media ${mobile} {
    height: initial;

    padding: 30vh 0 0;
  }
`;

const BoxWrapper = styled.div`
  width: 50%;
  position: relative;
  display: flex;
  flex-wrap: wrap;

  @media ${mobile} {
    margin: 4rem 0 0;
    width: 100%;
    border-top: 1px solid #3c3c3c;
  }
`;

const TextWrapper = styled.div`
  width: 50%;
  position: relative;
  color: #fff;
  border-right: 1px solid #3c3c3c;
  height: 100%;
  display: flex;
  padding-left: 5rem;
  flex-direction: column;
  justify-content: center;
  padding-right: 8rem;
  @media ${mobile} {
    width: 100%;
    padding-left: 24px;
    padding-right: 24px;
    border-right: 0px;
  }
  @media ${big} {
    padding-left: 7rem;
  }
`;

const Split = styled.div`
  display: flex;
  position: relative;
  width: 100%;
  height: 100%;
  @media ${mobile} {
    flex-direction: column;
  }
`;
const ButtonsWrapper = styled.div`
  display: flex;
  margin: 4rem 0 0;
  @media ${mobile} {
    display: none;
  }
`;
