import React, { useLayoutEffect } from "react";
import { Helmet } from "react-helmet";
import { PageTitle, PageImage, AboutInner, Cta } from "../components";
import styled from "styled-components";
import { images } from "../utils/images";
import { motion } from "framer-motion";
import { useLocation } from "react-router-dom";

export const About = () => {
  const location = useLocation();

  useLayoutEffect(() => {
    window.scrollTo(0, 0);
  }, [location.pathname]);

  return (
    <>
      <Helmet>
        <title>Nouhau Apie</title>
        <meta name="title" content="Nouhau" />
        <meta
          name="description"
          content="Nouhau Odontologijos klinikų konsultantas"
        />

        <meta property="og:type" content="website" />
        <meta property="og:url" content="https://nouhau.lt/" />
        <meta property="og:title" content="Nouhau" />
        <meta
          property="og:description"
          content="Nouhau Odontologijos klinikų konsultantas"
        />
        <meta property="og:image" content="%PUBLIC_URL%/nouhau-og.png" />

        <meta property="twitter:card" content="summary_large_image" />
        <meta property="twitter:url" content="https://nouhau.lt" />
        <meta property="twitter:title" content="Nouhau" />
        <meta
          property="twitter:description"
          content="Nouhau Odontologijos klinikų konsultantas"
        />
        <meta property="twitter:image" content="%PUBLIC_URL%/nouhau-og.png" />
      </Helmet>
      <PageWrapper>
        <PageOverlay
          as={motion.div}
          exit={{ opacity: 1, x: 0 }}
          initial={{ opacity: 1, x: 0, y: 0 }}
          animate={{ opacity: 1, x: 2000, y: 0 }}
          transition={{ duration: 1 }}
        />
        <PageTitle
          title="Odontologija nuolat keičiaisi"
          strong="mes padedame Jums keistis kartu"
        ></PageTitle>

        <PageImage image={images.about} />
        <AboutInner />
        <Cta />
      </PageWrapper>
    </>
  );
};

const PageWrapper = styled.div`
  background: #000;
  width: 100%;
  height: 100%;
`;

const PageOverlay = styled.div`
  background: #0f0f0f;
  width: 100%;
  height: 100%;
  position: fixed;
  z-index: 90;
`;
