import React from "react";
import styled from "styled-components";
import { Link, NavLink } from "react-router-dom";
import { mobile, big } from "../../styles/breakpoints";
import { icons } from "../../utils/icons";

import { motion, useCycle } from "framer-motion";

const Path = (props) => (
  <motion.path
    fill="transparent"
    strokeWidth="3"
    stroke="currentColor"
    strokeLinecap="round"
    {...props}
  />
);

const slideVerticalAnimation = {
  open: {
    rotateX: 0,
    y: 0,
    opacity: 1,
    transition: {
      duration: 0.5,
      mass: 0.2,
      type: "tween",
    },
    display: "block",
  },
  close: {
    rotateX: -15,
    y: "-100vh",
    opacity: 0,
    transition: {
      duration: 0.5,
    },
    transitionEnd: {
      display: "none",
    },
  },
};

export const Header = () => {
  const [isOpen, toggleDropdown] = useCycle(false, true);

  return (
    <>
      <HeaderWrapper>
        <MenuButton
          className="menu-button"
          onClick={toggleDropdown}
          animate={isOpen ? "open" : "closed"}
          initial={false}
        >
          <svg style={{ margin: "4px 0 0 2px" }} viewBox="0 0 23 23">
            <Path
              variants={{
                closed: { d: "M 2 2.5 L 20 2.5" },
                open: { d: "M 3 16.5 L 17 2.5" },
              }}
            />
            <Path
              d="M 2 9.423 L 20 9.423"
              variants={{
                closed: { opacity: 1 },
                open: { opacity: 0 },
              }}
              transition={{ duration: 0.1 }}
            />
            <Path
              variants={{
                closed: { d: "M 2 16.346 L 20 16.346" },
                open: { d: "M 3 2.5 L 17 16.346" },
              }}
            />
          </svg>
        </MenuButton>
        <Menu
          initial="close"
          animate={isOpen ? "open" : "close"}
          variants={slideVerticalAnimation}
        >
          <MenuItems>
            <MenuItem>
              <NavLink
                onClick={toggleDropdown}
                to="/"
                exact={true}
                activeClassName="active"
              >
                Pradinis
              </NavLink>
            </MenuItem>
            <MenuItem>
              <NavLink
                onClick={toggleDropdown}
                to="/about"
                activeClassName="active"
              >
                Apie
              </NavLink>
            </MenuItem>
            <MenuItem>
              <NavLink
                onClick={toggleDropdown}
                to="/services"
                activeClassName="active"
              >
                Paslaugos
              </NavLink>
            </MenuItem>
            <MenuItem>
              <NavLink
                onClick={toggleDropdown}
                to="/contact"
                activeClassName="active"
              >
                Kontaktai
              </NavLink>
            </MenuItem>
          </MenuItems>
        </Menu>

        <Flex>
          <LogoWrapper>
            <Link to="/">
              <Logo src={icons.logo2} />
            </Link>
          </LogoWrapper>
        </Flex>
      </HeaderWrapper>
    </>
  );
};

const MenuItem = styled.div`
  a {
    text-decoration: none;
    font-size: 68px;
    color: #fff;
    padding: 12px 0;
    font-weight: 300;
    opacity: 0.6;
    display: flex;
    transition: 0.2s all;
    &:hover {
      transform: scale(1.1)
      transition: 0.2s all;
      opacity: 1;
    }
    &.active {
      opacity: 1;
      font-weight: 500;
      background-image: linear-gradient(
        17deg,
        #cfebff,
        #fcbabc 36%,
        #dd5183 55%,
        #ba75e0
      );
      -webkit-background-clip: text;
      background-clip: text;
      -webkit-text-fill-color: transparent;
    }
  }
  @media ${mobile} {
    a {
      font-size: 44px;
    }
  }
`;
const MenuItems = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: center;
  align-items: center;
  @media ${mobile} {
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
`;
const LogoWrapper = styled.div`
  &:hover {
    cursor: pointer;
  }
`;
const MenuButton = styled(motion.div)`
  align-items: center;
  background: $dark;
  border: 0;
  border-radius: 50%;
  color: white;
  cursor: pointer;
  display: flex;
  height: 5rem;
  justify-content: center;
  outline: none;
  user-select: none;
  width: 5rem;
  z-index: 222;
  right: 5%;
  position: fixed;
  background: none;
  top: 2rem;
  svg {
    width: 23px;
    height: 23px;
  }
  @media ${mobile} {
    position: relative;
    height: 3rem;
    right: initial;
    top: initial;
    display: flex;
    justify-content: flex-end;
    svg {
      width: 23px;
      height: 19px;
    }
  }
`;
const Menu = styled(motion.div)`
  overflow-x: hidden;
  position: fixed;
  top: 0;
  left: 0;
  transition: height 0.5s;
  width: 100%;
  z-index: 200;
  overflow-y: hidden;
  background: #0f0f0f;
  border: none;
  border-radius: none;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
`;

const HeaderWrapper = styled.div`
  padding: 2rem 5rem 0rem;

  display: flex;
  align-items: center;
  overflow: hidden;
  position: fixed;

  top: 0;
  z-index: 10;
  @media ${mobile} {
    padding: 1rem 32px;
    height: initial;
    box-shadow: none;
    display: flex;
    justify-content: space-between;
    flex-direction: row-reverse;
    background: #0f0f0f;
    width: 100%;
    align-items: center;
  }
  @media ${big} {
    padding: 2rem 7rem 0rem;
  }
`;
const Flex = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const Logo = styled.img`
  height: 42px;
  @media ${mobile} {
    height: 34px;
  }
`;
