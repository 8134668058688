import React, { useLayoutEffect } from "react";
import { Helmet } from "react-helmet";
import styled from "styled-components";
import { H1 } from "../components";
import { images } from "../utils/images";
import { motion } from "framer-motion";
import { mobile } from "../styles/breakpoints";
import { useLocation } from "react-router-dom";

export const Contact = () => {
  const location = useLocation();

  useLayoutEffect(() => {
    window.scrollTo(0, 0);
  }, [location.pathname]);

  return (
    <>
      <Helmet>
        <title>Nouhau Kontaktai</title>
        <meta name="title" content="Nouhau" />
        <meta
          name="description"
          content="Nouhau Odontologijos klinikų konsultantas"
        />

        <meta property="og:type" content="website" />
        <meta property="og:url" content="https://nouhau.lt/" />
        <meta property="og:title" content="Nouhau" />
        <meta
          property="og:description"
          content="Nouhau Odontologijos klinikų konsultantas"
        />
        <meta
          property="og:image"
          content="https://srv337.hstgr.io:7443/files/public_html/nouhau-og.png"
        />

        <meta property="twitter:card" content="summary_large_image" />
        <meta property="twitter:url" content="https://nouhau.lt" />
        <meta property="twitter:title" content="Nouhau" />
        <meta
          property="twitter:description"
          content="Nouhau Odontologijos klinikų konsultantas"
        />
        <meta
          property="twitter:image"
          content="https://srv337.hstgr.io:7443/files/public_html/nouhau-og.png"
        />
      </Helmet>

      <PageWrapper>
        <PageOverlay
          as={motion.div}
          exit={{ opacity: 1, x: 0 }}
          initial={{ opacity: 1, x: 0, y: 0 }}
          animate={{ opacity: 1, x: 2000, y: 0 }}
          transition={{ duration: 1 }}
        />
        <Wrapper>
          <Split>
            <Left>
              <TextWrapper>
                <H1>
                  Susisiekite<br></br>
                  <strong> konsultacijai</strong>
                </H1>
              </TextWrapper>
            </Left>
            <Right>
              <ImageWrapper>
                <Image src={images.contact} />
              </ImageWrapper>
            </Right>
          </Split>
        </Wrapper>
      </PageWrapper>
    </>
  );
};
const PageWrapper = styled.div`
  background: #000;
  width: 100%;
  height: 100%;
`;

const PageOverlay = styled.div`
  background: #0f0f0f;
  width: 100%;
  height: 100%;
  position: fixed;
  z-index: 90;
`;

const Wrapper = styled.div`
  width: 100%;
  height: 100vh;
  display: flex;
  align-items: center;
  overflow: hidden;
  background: #0f0f0f;
  @media ${mobile} {
    padding: 35vh 0 0;
    height: initial;
  }
`;
const Left = styled.div`
  width: 50%;
  height: 100vh;
  @media ${mobile} {
    width: 100%;
    height: initial;
  }
`;
const Right = styled.div`
  width: 50%;
  height: 100vh;
  @media ${mobile} {
    width: 100%;
    height: initial;
  }
`;
const ImageWrapper = styled.div`
  width: 100%;
  height: 100%;
`;
const TextWrapper = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  padding-left: 5rem;
  @media ${mobile} {
    padding: 0 24px;
    margin: 0 auto 46px;
    text-align: center;
    justify-content: center;
  }
`;
const Split = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  @media ${mobile} {
    flex-direction: column;
  }
`;

const Image = styled.img`
  width: 100%;
  height: 105%;
  object-fit: cover;
`;
