import React from "react";
import styled from "styled-components";
import { icons } from "../../utils/icons";
import { mobile } from "../../styles/breakpoints";
import { H2 } from "../../components";
import { Swiper, SwiperSlide } from "swiper/react";
import SwiperCore, { Navigation, Scrollbar, EffectFade } from "swiper";
// Import Swiper styles
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "swiper/css/scrollbar";

import { useMediaQuery } from "react-responsive";
SwiperCore.use([Navigation, Scrollbar, EffectFade]);

const params = {
  loop: false,
  navigation: {
    nextEl: ".arrow-right",
    prevEl: ".arrow-left",
  },

  breakpoints: {
    320: {
      slidesPerView: 3.3,
      spaceBetween: 0,
    },
    1023: {
      slidesPerView: 3.3,
      spaceBetween: 0,
    },
    1680: {
      slidesPerView: 3.3,
      spaceBetween: 0,
    },
    2560: {
      slidesPerView: 3.3,
      spaceBetween: 0,
    },
  },
};

export const Steps = () => {
  const steps = [
    {
      number: "01",
      title: "Tikslas",
      description:
        "Koks Jūsų tikslas, kodėl Jūs tapote gydytoju odontologu, burnos higienistu, administratoriumi, ar kodėl tapote klinikos savininku?",
    },
    {
      number: "02",
      title: "Žmonės",
      description:
        "Atraskite savo žmones. Teisingi žmonės, su teisingai poreikiais, teisingose vietose, teisingiems tikslams.",
    },
    {
      number: "03",
      title: "Pelnas",
      description:
        "Atvirkščio skaičiavimo metodika. Pasakykite sau kiek norite uždirbti, tuomet pasakykite sau kiek pacientų norite priimti per dieną, kokias paslaugas ir už kiek tai norite suteikti savo pacientams. ",
    },
    {
      number: "04",
      title: "Tikslas",
      description: "aa",
    },
    {
      number: "05",
      title: "Tikslas",
      description: "aa",
    },
  ];

  const isMobile = useMediaQuery({ query: mobile });
  return (
    <Wrapper className="steps-swiper">
      <Container>
        <TitleWrapper>
          <H2>Principles</H2>
          <ButtonsWrapper>
            <ButtonNavigation className="arrow-left">
              <ButtonNavIcon src={icons.arrow_left} />
            </ButtonNavigation>
            <ButtonNavigation
              margin={isMobile ? "0 0 0 0.75rem" : "0 0 0 1rem"}
              className="arrow-right"
            >
              <ButtonNavIcon src={icons.arrow_right} />
            </ButtonNavigation>
          </ButtonsWrapper>
        </TitleWrapper>
      </Container>
      <InnerWrapper>
        <Swiper {...params}>
          {steps.map((step, index) => (
            <SwiperSlide key={step.index}>
              <Step>
                <StepNumber>{step.number}</StepNumber>
                <StepName>{step.title}</StepName>
                <StepDescription>{step.description}</StepDescription>
              </Step>
            </SwiperSlide>
          ))}
        </Swiper>
      </InnerWrapper>
    </Wrapper>
  );
};
const StepName = styled.div`
  width: 100%;
  font-size: 34px;
  color: #fff;
  font-weight: 500;
`;
const Step = styled.div`
  display: flex;
  flex-direction: column;
  padding: 5rem 5rem 5rem 5rem;
  border-right: 1px solid #3c3c3c;
  height: 60vh;
  transition: 0.4s all;
  background-image: none;
  &:hover {
    ${StepName} {
      font-weight: 500;
      background-image: linear-gradient(
        17deg,
        #cfebff,
        #fcbabc 36%,
        #dd5183 55%,
        #ba75e0
      );
      -webkit-background-clip: text;
      background-clip: text;
      -webkit-text-fill-color: transparent;
      transition: 0.4s all;
    }
  }
`;

const StepNumber = styled.div`
  width: 100%;
  font-size: 24px;
  color: #4c4c4c;
  font-weight: 400;
  margin: 0 0 40px;
`;
const StepDescription = styled.div`
  font-family: "Raleway", sans-serif;
  font-weight: 300;
  font-size: 18px;
  line-height: 1.8;
  display: flex;
  opacity: 0.6;
  color: #fff;
  letter-spacing: 0.005em;
  white-space: pre-line;
  margin: 24px 0 0;
`;

const Wrapper = styled.div`
  width: 100%;
  padding: 8.25rem 0% 0;
  position: relative;
  background: #0f0f0f;
  border-top: 1px solid #3c3c3c;
  @media ${mobile} {
  }
`;
const Container = styled.div`
  width: 100%;
  padding: 0;
  position: relative;
  @media ${mobile} {
  }
`;
const InnerWrapper = styled.div`
  width: 100%;
  position: relative;
  border-top: 1px solid #3c3c3c;
`;
const TitleWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 0 0 0rem;
  padding: 0 5% 70px;
`;
const ButtonsWrapper = styled.div`
  display: flex;
`;
const ButtonNavIcon = styled.img`
  height: 14px;
  opacity: 0.6;
`;
const ButtonNavigation = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 56px;
  width: 56px;
  border: 1px solid rgb(185 185 185 / 20%);
  border-radius: 20rem;
  margin: ${(props) => props.margin || "0"};
  transition: 0.2s all;
  @media ${mobile} {
    height: 48px;
    width: 48px;
  }
  &.swiper-button-disabled {
    opacity: 0.5;
  }
  &:hover {
    border: 1px solid #fff;
    cursor: pointer;
    transition: 0.2s all;
  }
  ${ButtonNavIcon} {
    opacity: 1;
    transition: 0.2s all;
  }
`;
