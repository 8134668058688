import React, { useEffect } from "react";
import styled from "styled-components";
import { mobile } from "../../styles/breakpoints";

import { useAnimation } from "framer-motion";
import { useInView } from "react-intersection-observer";
import { H2, Paragraph, H3, ServicesSection } from "../../components";

export const AboutInner = () => {
  const control = useAnimation();
  const [inView] = useInView();

  useEffect(() => {
    if (inView) {
      control.start("visible");
    } else {
      control.start("hidden");
    }
  }, [control, inView]);

  return (
    <Wrapper>
      <Split>
        <Left>
          <TitleWrapper>
            <H2>Apie mane</H2>
          </TitleWrapper>
        </Left>
        <Right>
          <TextWrapper>
            <H3 className="aboutInner">
              <strong>
                Su Jumis dalinamės daugiau nei 11 metų kauptą ir nuolat
                atnaujinamą patirtį odontologijos klinikų valdyme.
              </strong>
            </H3>
            <Paragraph>
              Odontologija - nuolat kintanti būsena, nesvarbu, ar tai būtų 1-21
              odontologines kėdes turinti klinika, ar gydymo protokolai, ar
              technologijos ir įrangos. Visa tai lyg gyvas, dinamiškas
              organizmas.
              <br></br>
              <br></br>
              Savo paslaugas teikėme įvairaus dydžio nuo 5-700 darbuotojų
              turinčiose odontologijos klinikų komandose.
              <br></br>
              <br></br>
              Nuo patalpų paieškos iki sėkmingos klinikos veiklos. Padėjome
              įsteigti net 85 naujas darbo vietas odontologams.
              <br></br>
              <br></br>
              Odontologų veikla neatsiejama nuo dantų technikų veiklos,
              sėkmingai įsteigėme 2 naujas dantų technikų laboratorijas su jų
              komandomis.
              <br></br>
              <br></br>
              Tobulinamės ir siekiame naujausių žinių, todėl savo rengiamuose
              seminaruose odontologijos klinikų vadovams ir jų komandoms galime
              užtikrinti aktualiausią informaciją.
            </Paragraph>
          </TextWrapper>
          <Achievements>
            <AchievementItem>
              <AchievementNumber>11</AchievementNumber>
              <AchievementText>
                Metų patirtis odontologijos verslo sektoriuje
              </AchievementText>
            </AchievementItem>
            <AchievementItem>
              <AchievementNumber>5-700</AchievementNumber>
              <AchievementText>
                Klinikose ir jų tinkluose, kurių darbuotojų skaičius siekė nuo 5
                iki 700 darbuotojų
              </AchievementText>
            </AchievementItem>
            <AchievementItem>
              <AchievementNumber>2</AchievementNumber>
              <AchievementText>
                Įsteigtos dantų technikų laboratorijos
              </AchievementText>
            </AchievementItem>
            <AchievementItem>
              <AchievementNumber>85</AchievementNumber>
              <AchievementText>
                Įsteigtų naujų darbo vietų gydytojams odontologams Lietuvoje
              </AchievementText>
            </AchievementItem>
          </Achievements>
        </Right>
      </Split>

      <ServicesSection />
    </Wrapper>
  );
};
const AchievementText = styled.p`
  width: 100%;
  font-size: 18px;
  font-weight: 400;
  color: #fff;
  margin: 24px 0 0;
  opacity: 0.6;
  line-height: 1.6;
`;
const AchievementNumber = styled.span`
  font-size: 74px;
  font-weight: 500;
  color: #fff;
  @media ${mobile} {
    font-size: 3rem;
  }
`;
const AchievementItem = styled.div`
  display: flex;
  flex-direction: column;
  padding: 10% 5rem;
  flex: 1 0 50%;
  &:nth-child(1) {
    border-right: 1px solid #3c3c3c;
    border-bottom: 1px solid #3c3c3c;
  }
  &:nth-child(2) {
    border-bottom: 1px solid #3c3c3c;
  }
  &:nth-child(3) {
    border-right: 1px solid #3c3c3c;
  }
  @media ${mobile} {
    padding: 2rem 0;

    flex: 1 0 100%;
      &:nth-child(1), &:nth-child(3)  {
        border-right: 0px;
  }
`;
const Achievements = styled.div`
  display: flex;
  flex-wrap: wrap;
  border-top: 1px solid #3c3c3c;
`;
const TitleWrapper = styled.div`
width: 100%;
padding-right: 5rem;
padding-left: 7rem;
padding-top: 6rem;
padding-bottom: 6rem;
}
  @media ${mobile} {
    padding: 4rem 32px 2rem;
  }
`;
const TextWrapper = styled.div`
  width: 100%;
  padding-right: 5rem;
  padding-left: 5rem;
  padding-top: 6rem;
  padding-bottom: 6rem;
  @media ${mobile} {
    padding: 0 0 4rem;
  }
`;

const Wrapper = styled.div`
  width: 100%;

  background: #0f0f0f;
  @media ${mobile} {
    padding: 0;
  }
`;
const Split = styled.div`
  display: flex;
  position: relative;
  width: 100%;
  height: 100%;
  border-bottom: 1px solid #3c3c3c;
  @media ${mobile} {
    flex-direction: column;
  }
`;
const Left = styled.div`
  width: 40%;
  border-right: 1px solid #3c3c3c;
  @media ${mobile} {
    width: 100%;
    border-bottom: 1px solid #3c3c3c;
    border-right: 0px;
  }
`;
const Right = styled.div`
  width: 60%;
  @media ${mobile} {
    width: 100%;
    padding: 4rem 32px;
  }
`;
