import { useEffect } from 'react';
import styled from 'styled-components';
import { mobile } from '../../styles/breakpoints';
import { H2, Accordion } from '../../components';
import { motion, useAnimation } from 'framer-motion';
import { useInView } from 'react-intersection-observer';
import { textTransition } from '../../animations/textTransition';

export const ServicesSection = () => {
  const control = useAnimation();
  const [ref, inView] = useInView();

  useEffect(() => {
    if (inView) {
      control.start('visible');
    } else {
      control.start('hidden');
    }
  }, [control, inView]);

  const services = [
    {
      id: '1',
      title: 'Klinikos vadovo mokymai',
      description:
        'Rengiame klinikos vadovams skirtus visos dienos praktikinius ir teorinius mokymus, Šių mokymų pagalba, klinikos vadovas, gali atpažinti dažniausiai odontologijos klinikoje daromas klaidas, pakeisti požiūrį į nusistovėjusį klinikų valdymą ir siekti maksimaliai gero rezultato savo klinikai.',
      color: '#22cc88',
    },
    {
      id: '2',
      title: 'Registratūros personalo mokymai',
      description:
        'Registratūra - klinikos veidas. Ji privalo būti profesionali. Pastebime, jog būtent ši klinikos pozicija gali klinikai atnešti labai daug pridėtinės vertės. Rengiame teorinius ir praktikinius kursus klinikos registratūros darbuotojams, kaip bendrauti su pacientais, atskleidžiame ko pacientai tikisi iš registratūros ir pan.',
      color: '#ffcc00',
    },
    {
      id: '3',
      title: 'Kursai gydytojams odontologams',
      description:
        'Nouhau komanda savo gretose turi didelę patirtį sukaupusius odontologus specialistus. Rengiame teorinius ir praktikinius kursus pradedantiesiems ir profesionalams odontologams protezavimo, chirurgijos ir implantacijos srityse. ',
      color: '#0099ff',
    },
    {
      id: '4',
      title: 'Klinikos veiklos analizė',
      description:
        'Atliekame funkcinę odontologijos klinikos analizę, sudarome strateginį planą. Padedame optimizuoti vidinius procesus efektyviai klinikos veiklai. Naudojame efektyvią duomenų apdorojimo programą.',
      color: '#ff0055',
    },
    {
      id: '5',
      title: 'Būtinoji klinikos dokumentacija',
      description:
        'Rengiame būtinuosius odontologijos klinikos veiklai reikalingus dokumentus. Darbo ir konfidencialumo sutartis, paslaugų pacientams teikimo sutartis, paciento sutikimai, vidaus tvarkos taisyklės ir pan. Norimų gaminti dantų protezų rūšių registravimo VASPVT prie SAM techninė dokumentacija.',
      color: '#22cc88',
    },
    {
      id: '6',
      title: 'Kokybės vadovo dokumentacija',
      description:
        'Rengiame minimalius asmens sveikatos priežiūros paslaugų kokybės reikalavimus atitinkančius dokumentus ir/ar kokybės vadovą, infekcijos kontrolės dokumentaciją. Šis dokumentų paketas (daugiau nei 500 psl.) apima visą kokybės sistemos dokumentaciją (KSD), pritaikytą individualiai kiekvienai odontologijos klinika.',
      color: '#22cc88',
    },
    {
      id: '7',
      title: 'Licencijos ir leidimai',
      description:
        'Atstovaujame odontologijos kliniką visose reikiamose su klinikos veikla susijusiose institucijose. Rengiame paraiškas ir išimame licencijas.',
      color: '#22cc88',
    },
    {
      id: '8',
      title: 'Mėnesinis konsultanto abonementas',
      description:
        'Priklausomai nuo Jūsų poreikių, konsultantas gali prižiūrėti ir konsultuoti Jūsų kliniką 24val. per savaitę Jūsų pasirinktą laiko terminą. ',
    },
    {
      id: '9',
      title: 'Konsultacijos klinikos veiklai',
      description:
        'Teikiame nemokamas nuotolines konsultacijas, telefonu, vaizdo skambučiu',
      color: '#22cc88',
    },
  ];

  return (
    <Wrapper className="services-swiper">
      <Split>
        <TitleWrapper>
          <H2
            as={motion.h2}
            ref={ref}
            variants={textTransition}
            initial="hidden"
            animate={control}
          >
            Paslaugos
          </H2>
        </TitleWrapper>

        <AccordionWrapper
          as={motion.div}
          ref={ref}
          variants={textTransition}
          initial="hidden"
          animate={control}
        >
          {services.map((service, index) => (
            <Accordion
              title={service.title}
              description={service.description}
            />
          ))}
        </AccordionWrapper>
      </Split>
    </Wrapper>
  );
};
const AccordionWrapper = styled.div`
  width: 50%;
  @media ${mobile} {
    width: 100%;
    padding: 2rem 0 0;
  }
`;
const Wrapper = styled.div`
  width: 100%;
  padding: 8rem 0;
  position: relative;
  background: #0f0f0f;
  // border-top: 1px solid #3c3c3c;
  @media ${mobile} {
    padding: 4rem 0 6rem;
  }
`;

const Split = styled.div`
  width: 100%;
  position: relative;
  display: flex;
  padding: 0 5%;
  @media ${mobile} {
    flex-direction: column;
    padding: 0 32px;
  }
`;

const TitleWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  margin: 0 0 0rem;
  width: 50%;
  margin: 0 0 0px;
  @media ${mobile} {
    width: 100%;
    text-align: left;
  }
`;
