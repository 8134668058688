import styled from "styled-components";
import { black, white } from "../../styles/colors";
import { mobile, big } from "../../styles/breakpoints";

export const Paragraph = styled.p`
  padding: ${(props) => props.padding || "0px"};
  font-family: "Raleway", sans-serif;
  margin: ${(props) => props.margin || "36px 0 0"};
  max-width: ${(props) => props.maxWidth || "100%"};
  font-weight: 300;
  font-size: ${(props) => props.fontSize || "20px"};
  line-height: 1.6;
  display: flex;
  color: ${(props) => props.color || white};
  opacity: 0.6;
  letter-spacing: 0.005em;
  white-space: pre-line;
  @media ${mobile} {
    font-size: ${(props) => props.fontSize || "16px"};
  }
  @media ${big} {
    font-size: ${(props) => props.fontSize || "24px"};
  }
`;

export const ParagraphPlain = styled.p`
  padding: ${(props) => props.padding || "0px"};
  font-family: "Raleway", sans-serif;
  margin: ${(props) => props.margin || "0"};
  max-width: ${(props) => props.maxWidth || "100%"};
  font-weight: 400;
  font-size: ${(props) => props.fontSize || "1.25rem"};
  line-height: 1.6;
  color: ${(props) => props.color || black};
  opacity: 0.6;
  white-space: pre-line;
`;
