import styled from "styled-components";
import { mobile, big } from "../../styles/breakpoints";

export const H2 = styled.h2`
  padding: ${(props) => props.padding || "0"};
  margin: ${(props) => props.margin || "0"};
  font-weight: ${(props) => props.fontWeight || 800};
  max-width: ${(props) => props.maxWidth || "100%"};
  font-size: ${(props) => props.fontSize || "2.25rem"};
  line-height: ${(props) => props.lineHeight || "1.4"};
  font-weight: 400;
  color: #fff;

  strong {
    font-weight: 400;
    background-image: linear-gradient(
      17deg,
      #cfebff,
      #fcbabc 36%,
      #dd5183 55%,
      #ba75e0
    );
    -webkit-background-clip: text;
    background-clip: text;
    -webkit-text-fill-color: transparent;
  }
  @media ${mobile} {
    font-size: ${(props) => props.fontSize || "1.75rem"};
  }
  @media ${big} {
    font-size: ${(props) => props.fontSize || "3rem"};
  }
`;
