import styled from "styled-components";
import React, { useState, useRef } from "react";
import { mobile, big } from "../../styles/breakpoints";
import Chevron from "./Chevron";
import useOnClickOutside from "../../hooks/useOnClickOutside";

export const Accordion = ({ title, description }) => {
  const [active, setActive] = useState("");
  const [height, setHeight] = useState("0px");
  const [rotation, setRotation] = useState("accordion__icon");

  const content = useRef();
  const sensitive = useRef();

  const toggleAccordion = () => {
    setActive(active === "" ? "active" : "");
    setHeight(
      active === "active" ? "0px" : `${content.current.scrollHeight}px`
    );
    setRotation(
      active === "active" ? "accordion__icon" : "accordion__icon rotate"
    );
  };

  useOnClickOutside(sensitive, () => {
    setActive("");
    setHeight("0px");
    setRotation("accordion__icon");
  });

  return (
    <AccordionSection ref={sensitive}>
      <AccordionButton
        className={`accordion ${active}`}
        onClick={toggleAccordion}
      >
        <AccordionTitle>{title}</AccordionTitle>

        <Chevron width={10} fill={"#777"} className={`${rotation}`} />
      </AccordionButton>
      <AccordionContent
        ref={content}
        style={{ maxHeight: `${height}` }}
        className="accordion__content"
      >
        <AccordionText>{description}</AccordionText>
      </AccordionContent>
    </AccordionSection>
  );
};
const AccordionTitle = styled.h3`
  font-family: "Raleway", sans-serif;
  color: #fff;
  opacity: 0.6;
  font-weight: 400;
  font-size: 22px;
  margin: 0;
  @media ${mobile} {
    font-size: 18px;
    font-weight: 300;
    text-align: left;
  }
  @media ${big} {
    font-size: 1.75rem;
    font-weight: 300;
  }
`;

const AccordionText = styled.div`
  font-weight: 300;
  font-size: 16px;
  padding: 18px;
  color: #fff;
  font-family: "Raleway", sans-serif;
  opacity: 0.6;
  line-height: 1.6;
  @media ${big} {
    font-size: 20px;
    padding: 18px 18px 24px;
  }
`;
const AccordionContent = styled.div`
  overflow: hidden;
  transition: max-height 0.6s ease;
`;
const AccordionButton = styled.button`
  background-color: transparent;
  cursor: pointer;
  padding: 18px;
  display: flex;
  align-items: center;
  border: none;
  outline: none;
  border-bottom: 1px solid #3c3c3c;
  transition: all 0.6s ease;
  justify-content: space-between;
  &:hover {
    border-bottom: 1px solid transparent;
    transition: all 0.6s ease;
    ${AccordionTitle} {
      background-image: linear-gradient(
        283deg,
        #cfebff,
        #fcbabc 36%,
        #dd5183 55%,
        #ba75e0
      );
      -webkit-background-clip: text;
      background-clip: text;
      -webkit-text-fill-color: transparent;
      opacity: 1;
      -webkit-trasnition: all 0.6s ease;
      trasnition: all 0.6s ease;
      font-weight: 400;
      opacity: 1;
      trasnition: all 0.6s ease;
    }
  }
  &.active {
    border-bottom: 1px solid transparent;
    ${AccordionTitle} {
      background-image: linear-gradient(
        283deg,
        #cfebff,
        #fcbabc 36%,
        #dd5183 55%,
        #ba75e0
      );
      -webkit-background-clip: text;
      background-clip: text;
      -webkit-text-fill-color: transparent;
      opacity: 1;
      -webkit-trasnition: all 0.6s ease;
      trasnition: all 0.6s ease;
      font-weight: 400;
      opacity: 1;
      trasnition: all 0.6s ease;
    }
    ${AccordionText} {
      opacity: 1;
      trasnition: all 0.6s ease;
    }
  }
  @media ${mobile} {
    padding: 18px 0;
    justify-content: space-between;
    svg {
      width: 8px;
    }
  }
`;
const AccordionSection = styled.div`
  display: flex;
  flex-direction: column;
  &:last-child {
    ${AccordionButton} {
      border-bottom: none;
    }
  }
`;
