import styled from "styled-components";
import { white } from "../../styles/colors";
import { mobile, big } from "../../styles/breakpoints";

export const H1 = styled.h1`
  padding: ${(props) => props.padding || "0"};
  margin: ${(props) => props.margin || "0"};
  font-weight: ${(props) => props.fontWeight || 400};
  font-size: ${(props) => props.fontSize || "4.5rem"};
  line-height: ${(props) => props.lineHeight || "1.2"};
  color: ${(props) => props.color || white};
  letter-spacing: -0.1rem;
  strong {
    font-weight: 400;
    background-image: radial-gradient(
      circle farthest-corner at 100% 0,
      #ffaccf 28%,
      #5cb6f8 51%,
      #ba75e0 87%,
      #dd5183 96%
    );
    -webkit-background-clip: text;
    background-clip: text;
    -webkit-text-fill-color: transparent;
  }
  &.cta {
    font-weight: 400;
    letter-spacing: -0.2rem;
    strong {
      font-weight: 400;
    }
  }
  &.pageTitle {
    font-size: 4rem;
    max-width: 100%;
  }

  @media ${mobile} {
    font-size: ${(props) => props.fontSize || "3rem"};
    &.article {
      font-size: 2.5rem;
    }
    &.cta {
      font-size: 2.5rem;
      font-weight: 400;
      letter-spacing: -0.1rem;
    }
    &.pageTitle {
      font-size: 2.5rem;
    }
  }
  @media ${big} {
    font-size: ${(props) => props.fontSize || "5.5rem"};
  }
`;
