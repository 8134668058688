import React from "react";
import ReactGA from "react-ga4";
import { Header, Footer } from "./components";
import { Home, About, Services, Contact, Article } from "./routes";
import "./index.scss";
import {
  BrowserRouter as Router,
  Route,
  useLocation,
  Switch,
} from "react-router-dom";
import { AnimatePresence } from "framer-motion";

ReactGA.initialize([
  {
    trackingId: "G-W89QBWY986",
  },
]);

ReactGA.send({ hitType: "pageview", page: window.location.pathname });

const AnimatedRoutes = ({ children }) => {
  const location = useLocation();

  return (
    <AnimatePresence
      exitBeforeEnter={true}
      // initial={false}
    >
      <Switch location={location} key={location.pathname}>
        {children}
      </Switch>
    </AnimatePresence>
  );
};

export const App = () => {
  return (
    <Router>
      <>
        <Switch>
          <Route component={Header} />
        </Switch>

        <AnimatedRoutes>
          <Route exact path="/" component={Home} />
          <Route exact path="/about" component={About} />
          <Route exact path="/services" component={Services} />
          <Route exact path="/contact" component={Contact} />
          <Route path="/article" component={Article} />
        </AnimatedRoutes>

        <Switch>
          <Route component={Footer} />
        </Switch>
      </>
    </Router>
  );
};
