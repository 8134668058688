import logo from '../assets/icons/logo.svg';
import logo2 from '../assets/icons/logo2.svg';
import rightarrowcircle from '../assets/icons/rightarrowcircle.svg';
import arrowleft from '../assets/icons/arrowleft.svg';
import circle from '../assets/icons/circle.svg';
import mir from '../assets/icons/mir.svg';
import mir2 from '../assets/icons/mir2.svg';
import dpc from '../assets/icons/dpc.svg';
import aik from '../assets/icons/aik.svg';
import ladenta from '../assets/icons/ladenta.svg';
import vivadens from '../assets/icons/vivadens.svg';
import viladenta from '../assets/icons/viladenta.svg';
import signontab from '../assets/icons/signontab.svg';
import facebook from '../assets/icons/facebook.svg';
import linkedin from '../assets/icons/linkedin.svg';
import instagram from '../assets/icons/instagram.svg';
import youtube from '../assets/icons/youtube.svg';

import about_differ from '../assets/icons/about_differ.svg';
import about_differ_mobile from '../assets/icons/about_differ_mobile.svg';

import about_deco_left from '../assets/icons/about_deco_left.svg';
import about_deco_right from '../assets/icons/about_deco_right.svg';
import appstore_black from '../assets/icons/appstore_black.svg';
import googleplay_black from '../assets/icons/googleplay_black.svg';
import appstore_white from '../assets/icons/appstore_white.svg';
import googleplay_white from '../assets/icons/googleplay_white.svg';
import arrow_left from '../assets/icons/arrow_left.svg';
import arrow_right from '../assets/icons/arrow_right.svg';
import back_button_arrow from '../assets/icons/back_button_arrow.svg';

import chat from '../assets/icons/chat.svg';
import phone from '../assets/icons/phone.svg';
import calendar from '../assets/icons/calendar.svg';
import email from '../assets/icons/email.svg';

export const icons = {
  logo,
  logo2,
  chat,
  phone,
  circle,
  email,
  calendar,
  rightarrowcircle,

  arrowleft,

  mir,
  mir2,
  dpc,
  vivadens,
  ladenta,
  viladenta,
  signontab,
  aik,
  facebook,
  linkedin,
  instagram,
  youtube,

  about_differ,
  about_differ_mobile,

  appstore_black,
  googleplay_black,
  appstore_white,
  googleplay_white,
  about_deco_left,
  about_deco_right,
  arrow_left,
  arrow_right,
  back_button_arrow,
};
