import React, { useLayoutEffect } from "react";
import { Helmet } from "react-helmet";
import { ArticlePage, Cta } from "../components";
import styled from "styled-components";
import { images } from "../utils/images";
import { motion } from "framer-motion";
import { Route, useLocation, useRouteMatch } from "react-router-dom";

export const Article = () => {
  const location = useLocation();

  useLayoutEffect(() => {
    window.scrollTo(0, 0);
  }, [location.pathname]);

  const articles_list = [
    {
      id: 1,
      title: "Kodėl Jūsų Pacientai Atšaukia Vizitus?",
      image: images.article1,
      url: "/article/1",
      date: "Rugsėjo 28",
      year: "2022",
    },
    {
      id: 2,
      title: "Galbūt Tai Svarbiausias Jūsų Klinikos Komandos Narys?",
      image: images.article2,
      url: "/article/2",
      date: "Rugsėjo 23",
      year: "2022",
    },
    {
      id: 3,
      title:
        "Kaip Dirbant Mažiau Uždirbti daugiau? Kas Tas Gydymo Koordinatorius?",
      image: images.article3,
      url: "/article/3",
      date: "Liepos 6",
      year: "2022",
    },
  ];
  const { url } = useRouteMatch();

  return (
    <>
      <Helmet>
        <title>Nouhau</title>
        <meta name="title" content="Nouhau" />
        <meta
          name="description"
          content="Nouhau Odontologijos klinikų konsultantas"
        />

        <meta property="og:type" content="website" />
        <meta property="og:url" content="https://nouhau.lt/" />
        <meta property="og:title" content="Nouhau" />
        <meta
          property="og:description"
          content="Nouhau Odontologijos klinikų konsultantas"
        />
        <meta
          property="og:image"
          content="https://srv337.hstgr.io:7443/files/public_html/nouhau-og.png"
        />

        <meta property="twitter:card" content="summary_large_image" />
        <meta property="twitter:url" content="https://nouhau.lt" />
        <meta property="twitter:title" content="Nouhau" />
        <meta
          property="twitter:description"
          content="Nouhau Odontologijos klinikų konsultantas"
        />
        <meta
          property="twitter:image"
          content="https://srv337.hstgr.io:7443/files/public_html/nouhau-og.png"
        />
      </Helmet>
      <PageWrapper>
        <PageOverlay
          as={motion.div}
          exit={{ opacity: 1, x: 0 }}
          initial={{ opacity: 1, x: 0, y: 0 }}
          animate={{ opacity: 1, x: 2000, y: 0 }}
          transition={{ duration: 1 }}
        />

        <Route path={`${url}/:articleId`}>
          <ArticlePage data={articles_list} />
        </Route>
        <Cta />
      </PageWrapper>
    </>
  );
};

const PageWrapper = styled.div`
  background: #000;
  width: 100%;
  height: 100%;
`;

const PageOverlay = styled.div`
  background: #0f0f0f;
  width: 100%;
  height: 100%;
  position: fixed;
  z-index: 90;
`;
