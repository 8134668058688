import { useEffect } from "react";
import styled from "styled-components";
import { icons } from "../../utils/icons";
import { mobile } from "../../styles/breakpoints";
import { H1, DefaultButton } from "../../components";
import { motion, useAnimation } from "framer-motion";
import { useInView } from "react-intersection-observer";
import { textTransition } from "../../animations/textTransition";

export const Cta = () => {
  const control = useAnimation();
  const [ref, inView] = useInView();

  useEffect(() => {
    if (inView) {
      control.start("visible");
    } else {
      control.start("hidden");
    }
  }, [control, inView]);

  return (
    <Wrapper className="services-swiper">
      <InnerWrapper>
        <H1
          as={motion.h1}
          ref={ref}
          variants={textTransition}
          initial="hidden"
          animate={control}
          className="cta"
        >
          Ar jūs jaučiate, kad <strong>Jūsų klinika gali geriau?</strong>
        </H1>
      </InnerWrapper>
      <ButtonWrapper
        as={motion.div}
        ref={ref}
        variants={textTransition}
        initial="hidden"
        animate={control}
      >
        <DefaultButton url="mailto:hello@nouhau.lt" target="_blank">
          Registruokitis nemokamai konsultacijai{" "}
          <Icon src={icons.rightarrowcircle} />
        </DefaultButton>
      </ButtonWrapper>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  width: 100%;
  padding: 12rem 5%;
  position: relative;
  background: #0f0f0f;
  border-top: 1px solid #3c3c3c;

  @media ${mobile} {
    padding: 8rem 32px;
  }
`;
const Icon = styled.img`
  display: flex;
  height: 18px;
  margin-left: 12px;
  @media ${mobile} {
    display: none;
  }
`;

const ButtonWrapper = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 60px 0 0;
`;
const InnerWrapper = styled.div`
  width: 100%;
  position: relative;
  padding: 0 20%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
  @media ${mobile} {
    padding: 0 0;
  }
`;
