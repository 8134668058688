import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import { mobile, big } from "../../styles/breakpoints";
import { icons } from "../../utils/icons";
import { primary, black, white, grey, border } from "../../styles/colors";
const textMotion = {
  rest: {
    y: 0,
    transition: {
      duration: 0.6,
      type: "tween",
      ease: "easeIn",
    },
  },
  hover: {
    y: -56,
    transition: {
      duration: 0.8,
      type: "tween",
      ease: "easeOut",
    },
  },
};

export const SmallButton = ({
  onClick,
  label,
  disabled,
  type,
  className,
  icon,
  children,
}) => {
  const handleClick = () => {
    if (!disabled && onClick) {
      onClick();
    }
  };
  return (
    <Wrapper
      disabled={disabled}
      type={type}
      onClick={handleClick}
      className={className}
    >
      {label}
      {icon ? <ButtonIcon src={icon} /> : null}
    </Wrapper>
  );
};

SmallButton.propTypes = {
  type: PropTypes.string,
  className: PropTypes.string,
  label: PropTypes.string,
  onClick: PropTypes.func,
  disabled: PropTypes.bool,
};

SmallButton.defaultProps = {
  type: "button",
  disabled: false,
};

const Wrapper = styled.button`
  font-family: "Raleway", sans-serif;
  border: 0px solid #fff;
  background: transparent;
  color: #fff;
  font-weight: 600;
  font-size: 14px;
  padding: 0;
  border-radius: 0px;
  -webkit-transition: background 0.2s;
  transition: background 0.2s;
  cursor: pointer;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: -webkit-max-content;
  width: -moz-max-content;
  width: max-content;
  position: relative;
  text-center: center;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  align-items: center;

  &.small {
    padding: 11px 24px;
    font-size: 14px;
  }
  &.white {
    background: ${white};
    color: ${black};
  }
  &.backButton {
    background: ${grey};
    border-radius: 48px;
  }
  &.filterButton {
    background: none;
    font-size: 14px;
    line-height: 24px;
    padding: 4px 32px;
    border-radius: 100px;
    border: 1px solid ${border};
    margin-right: 1rem;
  }
  &.w100 {
    width: 100%;
  }
  @media ${big} {
    font-size: 18px;
  }
`;

const ButtonIcon = styled.img`
  display: flex;
  height: 12px;
  margin-left: 8px;
`;
