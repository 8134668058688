import React, { useEffect } from "react";
import styled from "styled-components";
import { H1, FooterBox } from "..";
import { mobile, big } from "../../styles/breakpoints";
import { icons } from "../../utils/icons";
import { useMediaQuery } from "react-responsive";
import { motion, useAnimation } from "framer-motion";
import { useInView } from "react-intersection-observer";
import { textTransition } from "../../animations/textTransition";

export const Footer = () => {
  const isMobile = useMediaQuery({ query: mobile });
  const control = useAnimation();
  const [ref, inView] = useInView();

  useEffect(() => {
    if (inView) {
      control.start("visible");
    } else {
      control.start("hidden");
    }
  }, [control, inView]);

  return (
    <>
      <FooterWrapper>
        <Split>
          <Left>
            <H1
              as={motion.h1}
              ref={ref}
              variants={textTransition}
              initial="hidden"
              animate={control}
            >
              Turite klausimų?<br></br> <strong>Pasikalbėkime</strong>
            </H1>

            {isMobile ? null : (
              <>
                <InfoWrapper
                  as={motion.div}
                  ref={ref}
                  variants={textTransition}
                  initial="hidden"
                  animate={control}
                >
                  <a href="tel:+37060452254">+370 604 52254</a>
                  <a href="mailto:hello@nouhau.lt">hello@nouhau.lt</a>
                </InfoWrapper>
                <Social>
                  <span>Follow us</span>
                  <SocialWrapper>
                    <a
                      href="https://www.facebook.com/nouhaudental"
                      target="_blank"
                      rel="noreferrer"
                    >
                      <img src={icons.facebook} alt="Facebook" />
                    </a>
                    <a
                      href="https://www.linkedin.com/company/nouhau-dental/"
                      target="_blank"
                      rel="noreferrer"
                    >
                      <img src={icons.linkedin} alt="LinkedIn" />
                    </a>
                    <a
                      href="https://www.instagram.com/nouhaudental/"
                      target="_blank"
                      rel="noreferrer"
                    >
                      <img src={icons.instagram} alt="Instagram" />
                    </a>
                    <a
                      href="https://www.youtube.com/channel/UCFLt1o0npclndAMn5ojiotw"
                      target="_blank"
                      rel="noreferrer"
                    >
                      <img src={icons.youtube} alt="Youtube" />
                    </a>
                  </SocialWrapper>
                </Social>
              </>
            )}
          </Left>
          <Right>
            <FooterBox
              label="Susisiekti telefonu"
              secondaryLabel="Susisiekti telefonu"
              icon={icons.phone}
              url="tel:+37060452254"
            />
            <FooterBox
              label="Susisiekti el.paštu"
              secondaryLabel="Susisiekti el.paštu"
              icon={icons.email}
              url="mailto:hello@nouhau.lt"
            />
            <FooterBox
              label="Rezervuoti konsultaciją"
              secondaryLabel="Rezervuoti konsultaciją"
              icon={icons.calendar}
              url="mailto:hello@nouhau.lt"
            />
            <FooterBox
              label="Susisiekite"
              secondaryLabel="Susisiekite"
              icon={icons.chat}
              url="tel:+37060452254"
            />
          </Right>
        </Split>
        {isMobile ? (
          <Social>
            <span>Sekite mus</span>
            <SocialWrapper>
              <a
                href="https://www.facebook.com/nouhaudental"
                target="_blank"
                rel="noreferrer"
              >
                <img src={icons.facebook} alt="Facebook" />
              </a>
              <a
                href="https://www.linkedin.com/company/nouhau-dental/"
                target="_blank"
                rel="noreferrer"
              >
                <img src={icons.linkedin} alt="LinkedIn" />
              </a>
              <a
                href="https://www.instagram.com/nouhaudental/"
                target="_blank"
                rel="noreferrer"
              >
                <img src={icons.instagram} alt="Instagram" />
              </a>
              <a
                href="https://www.youtube.com/channel/UCFLt1o0npclndAMn5ojiotw"
                target="_blank"
                rel="noreferrer"
              >
                <img src={icons.youtube} alt="Youtube" />
              </a>
            </SocialWrapper>
          </Social>
        ) : null}
      </FooterWrapper>
      <FooterBottom>
        <p>
          © {new Date().getFullYear()} Nouhau Dental Management | Visos teisės
          saugomos
        </p>
        <span>
          Website Created by:
          <a
            href="https://www.linkedin.com/in/audriusrazanauskas/"
            target="_blank"
            rel="noreferrer"
          >
            Audrius R.
          </a>
        </span>
      </FooterBottom>
    </>
  );
};
const FooterBottom = styled.div`
  width: 100%;
  background: #0f0f0f;
  padding: 1rem 5rem;
  border-top: 1px solid #3c3c3c;
  display: flex;
  justify-content: space-between;
  align-items: center;

  p {
    font-size: 14px;
    font-weight: 400;
    color: #fff;
    color: rgba(255, 255, 255, 0.6);
  }
  span {
    font-size: 14px;
    font-weight: 400;
    color: rgba(255, 255, 255, 0.6);
    opacity: 0.6;
    a {
      font-size: 14px;
      font-weight: 400;
      color: rgba(255, 255, 255, 0.6);

      text-decoration: none;
      margin-left: 5px;
      transition: 0.2s all;
      &:hover {
        color: rgba(255, 255, 255, 1);
        transition: 0.2s all;
      }
    }
  }
  @media ${mobile} {
    flex-direction: column;
    padding: 1rem 24px;
    text-align: center;
    p {
      font-size: 12px;
    }
    span {
      font-size: 12px;
      a {
        font-size: 12px;
      }
    }
  }
`;
const FooterWrapper = styled.div`
  width: 100%;
  padding: 0;
  background: #0f0f0f;
  border-top: 1px solid #3c3c3c;
  @media ${mobile} {
    padding: 30vh 0 3rem;
    border-bottom: 1px solid #0f0f0f;
  }
`;
const Split = styled.div`
  display: flex;
  justify-content: space-between;
  @media ${mobile} {
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
`;
const Left = styled.div`
  display: flex;
  flex-direction: column;
  width: 50%;
  justify-content: center;
  padding: 0 7% 0 5%;
  border-right: 1px solid #3c3c3c;
  @media ${mobile} {
    width: 100%;
    border-bottom: 1px solid #3c3c3c;
    border-right: 0px;
    padding: 0 32px 3rem;
  }
`;
const Right = styled.div`
  display: flex;
  flex-wrap: wrap;
  width: 50%;
  @media ${mobile} {
    width: 100%;
  }
`;
const InfoWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin: 80px 0 40px;
  p {
    font-size: 18px;
    color: #fff;
  }
  a {
    font-size: 18px;
    color: #fff;
    text-decoration: none;
    font-weight: 400;
    margin: 0 0 12px;

    img {
      height: 20px;
    }
  }
  @media ${big} {
    p {
      font-size: 22px;
    }
    a {
      font-size: 22px;
    }
  }
`;
const Social = styled.div`
  display: flex;
  flex-direction: column;
  span {
    margin: 15% 0 0;
    font-size: 16px;
    color: #707070;
    font-weight: 500;
    margin-bottom: 30px;
  }
  @media ${mobile} {
    span {
      text-align: center;
    }
  }
  @media ${big} {
    span {
      font-size: 20px;
    }
  }
`;

const SocialWrapper = styled.div`
  display: flex;
  align-items: center;
  a {
    margin-right: 15px;
    img {
      height: 18px;
    }
  }
  @media ${mobile} {
    justify-content: center;
  }
`;
