import React, { useEffect } from "react";
import styled from "styled-components";
import { mobile } from "../../styles/breakpoints";
import { useMediaQuery } from "react-responsive";
import { icons } from "../../utils/icons";
import { motion, useAnimation } from "framer-motion";
import { useInView } from "react-intersection-observer";
import { DefaultButton, H2, Paragraph } from "../../components";
import Lottie from "lottie-react";
import groovyWalkAnimation from "../../assets/lottie/circlewhite2.json";
import { textTransition } from "../../animations/textTransition";

export const About = () => {
  const isMobile = useMediaQuery({ query: mobile });

  const control = useAnimation();
  const [ref, inView] = useInView();

  useEffect(() => {
    if (inView) {
      control.start("visible");
    } else {
      control.start("hidden");
    }
  }, [control, inView]);

  return (
    <Wrapper>
      <Split>
        <ImageWrapper>
          <Lottie animationData={groovyWalkAnimation} loop={true} />
        </ImageWrapper>
        <TextWrapper>
          <H2
            as={motion.h2}
            ref={ref}
            variants={textTransition}
            initial="hidden"
            animate={control}
          >
            <strong>
              Klinikos sėkmę nulemia ne tik pacientų srautai, bet ir tai, kaip
              klinika valdoma.
            </strong>
          </H2>
          <Paragraph
            as={motion.p}
            ref={ref}
            variants={textTransition}
            initial="hidden"
            animate={control}
            maxWidth={isMobile ? "100%" : "90%"}
          >
            Mes nuoširdžiai su Jumis pasidalinsime idėjomis ir veiksmais, kurie
            yra patikrinti, įrodyti ir velniškai galingi, kurie leis atskleisti
            Jūsų klinikos potencialą.
          </Paragraph>
          <ButtonWrapper
            as={motion.div}
            ref={ref}
            variants={textTransition}
            initial="hidden"
            animate={control}
          >
            <DefaultButton url="/about">
              Apie <Icon src={icons.rightarrowcircle} />
            </DefaultButton>
          </ButtonWrapper>
        </TextWrapper>
      </Split>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  width: 100%;
  padding: 8.25rem 0;
  position: relative;
  background: #0f0f0f;
  border-top: 1px solid #3c3c3c;
  @media ${mobile} {
    padding: 4rem 0;
  }
`;
const ButtonWrapper = styled.div`
  margin: 50px 0 0;
  @media ${mobile} {
    display: flex;
    justify-content: center;
  }
`;
const Split = styled.div`
  display: flex;
  position: relative;
  width: 100%;
  height: 100%;
  @media ${mobile} {
    flex-direction: column;
  }
`;
const Icon = styled.img`
  display: flex;
  height: 18px;
  margin-left: 12px;
`;
const TextWrapper = styled.div`
  width: 50%;
  padding: 0 10% 0 40px;
  margin: 0 auto 0px;
  position: relative;
  display: flex;
  align-items: left;
  justify-content: center;
  flex-direction: column;
  @media ${mobile} {
    width: 100%;
    padding: 0 24px;
    text-align: center;
  }
`;

const ImageWrapper = styled.div`
  width: 50%;
  position: relative;
  z-index: 2;
  display: flex;
  align-items: center;
  justify-content: center;

  @media ${mobile} {
    width: 80%;
    margin: 0 auto;
  }
`;
