import styled from 'styled-components';
import React from 'react';
import PropTypes from 'prop-types';
import { H1 } from '../../components';
import { mobile } from '../../styles/breakpoints';
import { useParams } from 'react-router-dom';
import { H3, Paragraph } from '../../components';

export const ArticlePage = ({ data }) => {
  const { articleId } = useParams();
  const article = data.find((p) => p.id === Number(articleId));
  let articles_list;

  if (article) {
    let articles_list = (
      <div>
        <h3> {article.title} </h3>
        <p>{article.description}</p>
        <hr />
        <h4>{article.status}</h4>
      </div>
    );
  } else {
    let articles_list = <h2> Sorry. Article doesn't exist </h2>;
  }

  return (
    <Wrapper>
      <Container>
        <ArticleTitle>
          <span>Straipsniai</span>
          <H1 className="article">{article.title}</H1>
          <ArticleDate>
            {article.date}, {article.year}
          </ArticleDate>
        </ArticleTitle>
        <ArticleImageWrapper>
          <ArticleImage src={article.image} />
        </ArticleImageWrapper>
        <ArticleContent>
          {articleId === '1' ? (
            <>
              <Block>
                <H3>Ne šiaip vizitas</H3>
                <Paragraph>
                  Kartais tenka girdėti, kaip odontologijos klinikų
                  registratūros darbuotojai atšaukinėja pacientų vizitus
                  nepaaiškindami kam apskritai pacientui reikalingas vizitas į
                  kurį jis buvo užsiregistravęs. Juk vizitas reikalingas ne
                  šiaip pasisvečiuoti klinikoje pas gydytoją odontologą, vizitas
                  yra būtinas tęsti, ar pradėti gydymą. <br></br> <br></br>
                  Paprastai tai registratūros darbuotojai nuolat leidžia
                  pacientams vėluoti, nukelti vizitus, į kuriuos pacientai patys
                  registravosi ir patvirtino, kad atvyks. Ir šie darbuotojai tai
                  daro be jokio paaiškinimo apie pirminę vizito priežastį, ar
                  gydymo svarbos, kuris pacientams buvo diagnozuotas.
                </Paragraph>
              </Block>
              <Block>
                <H3>
                  “Nieko tokio, viskas gerai. Galime pasiūlyti kitą autobusą...”
                </H3>
                <Paragraph>
                  Apie vizitą jie kalbasi tarsi tai būtų diskusija apie
                  autobusą: “Jūs negalite suspėti į šį autobusą? Nieko tokio,
                  viskas gerai. Galime pasiūlyti kitą autobusą, kuris išvyksta
                  už penkiolikos minučių.”
                  <br></br> <br></br>
                  Beveik niekada negirdžiu registratūros darbuotojo kalbant apie
                  paslaugą, kuri yra perkeliama kartu su paciento vizitu. Beveik
                  niekada negirdžiu pokalbio apie gydymo pasekmes, kurios gali
                  atsirasti jei vizitas bus perkeltas.
                  <br></br> <br></br>
                  Dažniausiai girdžiu tai: “Viskas gerai. Ar Jūs norėtumėte
                  pasiskirti sekantį vizitą?”
                  <br></br> <br></br>
                  Mes visi puikiai žinome, kad negaudami reikalingos odontologo
                  paslaugos, kai tai būtina, leidžiame infekcijoms paūmėti,
                  tarpams padidėti, kišenėms gilėti ir t.t.
                  <br></br> <br></br>
                  Vizitas pas gydytoją odontologą tai nėra susitikimas atsigerti
                  kavos puodelio. Tai yra gydymo plano numatytas ir suplanuotas
                  vizitas pašalinti patalogiją, kuri nepasišalins ir neišsigydys
                  pati. Minimaluzuojant vizito priežastis ir tikslus tikrai
                  nepridės net menkiausios naudos Jūsų pacientams.{' '}
                </Paragraph>
              </Block>
              <Block>
                <H3>Ką iš tikrųjų pasakote savo pacientams</H3>
                <Paragraph>
                  Kuomet Jūs sakote savo pacientams, kad norimas nukelti vizitas
                  yra “tik burnos higiena”, Jūs iš tiesų pasakote štai ką: “Aš
                  leidžiu, kad pataloginės bakterijos užkrėstų dantenas, plistų,
                  daugintųsi ir toliau darytų žalą Jūsų burnos ertmėje...”
                  <br></br> <br></br>
                  Kuomet Jūs sakote, kad Jūsų suplanuotas vizitas “tik
                  plombavimas”, Jūs iš tiesų savo pacientams suteikiate sąlygas:
                  “Leisti dantų ėduoniui sugriaužti sveiką danties struktūra ir
                  artėti prie dantų nervo...”
                  <br></br> <br></br>
                  Leisdami pacientams nukelti, ar net atšaukti numatytas ir
                  jiems reikalingas odontologines paslaugas, Jūsų pacientams tai
                  tikrai nepadarys jokios naudos.
                  <br></br> <br></br>
                  Vizitas pas odontologą skirtas svarbiam tikslui. Tai yra Jūsų
                  klinikos pareiga sukontroliuoti, kad visi vizitai yra
                  išlaikomi būtinoms odontologo paslaugoms suteikti, kaip tai
                  numato gydymo planas.
                  <br></br> <br></br>
                  Išlaikyti ir neperkelti vizitai leidžia pataloginėms
                  situacijoms išnykti.
                  <br></br> <br></br>
                  Jūsų, kaip odontologijos profesionalo tikslas, yra padėti
                  pacientams užkirsti kelią burnos ertėms audinių pablogėjimui,
                  ar net sunaikinimui.
                  <br></br> <br></br>
                  Leisdami pacientams rinktis atidėti, ar atšaukti reikalingus
                  vizitus pas gydytoją odontologą nėra ir neturėtų būti
                  optimalus rūpesčio pacientams standartas
                </Paragraph>
              </Block>
            </>
          ) : null}

          {articleId == '2' ? (
            <>
              <Block>
                <H3>Komandos sudėtis: 2 darbuotojai</H3>
                <Paragraph>
                  Prieš gerus dešimt, ar kiek daugiau metų, odontologijos
                  klinikos komandos sudėtis buvo labai paprasta ir aiški:
                  <br></br> <br></br>
                  Gydytojas odontologas, kuris visas paslaugas atlieka pats,
                  nesvarbu, ar tai burnos higiena, ar endo-pergydymas, ar sinuso
                  pakėlimas. Visgi suvokimas buvo toks, kad kaip gi aš,
                  gydytojas, galiu protezuoti vainikėlį ant kanalų, kuriuos
                  pergydė kažkoks kitas gydytojas.
                  <br></br> <br></br>
                  Antrasis komandos narys - asistentas. Ir viskas... nes
                  asistentas tuo pačiu ir patalpų valytojas, registratūros
                  darbuotojas, kasininkas ir asmeninis gydytojo laiko
                  tvarkytojas.
                </Paragraph>
              </Block>
              <Block>
                <H3>Iki kol pradėjo kažkas keistis...</H3>
                <Paragraph>
                  Keistis pradėjo daug kas, o kalbant apie komandinio darbo
                  supratimą - ypatingai. Šiai dienai specializacijos gydyme
                  būtinybė ir darbas komandoje, siekiant gerai išpildyti
                  suplanuotą gydymo planą yra neišvengiama, teisinga realybė.
                </Paragraph>
              </Block>
              <Block>
                <H3>Klaida, kurios reikėtų vengti</H3>
                <Paragraph>
                  Labai liūdna, tačiau klinikos, norėdamos turėti didesnius
                  pacientų srautus, koncentruojasi į greitus ir trumpalaikius
                  sprendimus. Klinikos suteikdamos ženklią nuolaidą gydymui,
                  tikisi jog pacientas “užkibs” ir pasiliks.
                  <br></br> <br></br>
                  Deja... tai yra klaida, kurios reikėtų vengti. Pacientų,
                  “nuolaidų medžiotojų”, klinikai geriau neturėti. Tokie
                  pacientai tiesiog pasinaudos Jūsų siūloma nuolaida ir paliks
                  Jūsų kliniką taip ir netapdami lojaliais pacientais.
                  <br></br> <br></br>
                  Dažniausiai klinikos rinkdamosios kokiai paslaugai padaryti
                  super patrauklią akciją/nuolaidą, pasirenka burnos higienos
                  paslaugą.
                  <br></br> <br></br>
                  Klinikoms atrodo, kad būtent ši paslauga pacientams yra
                  labiausiai suprantama, dažniausiai girdėta. Nes juk čia
                  “skaldomi akmenys” ir tiek, kas čia dar tokio gali būti
                  sudėtingo. Plius ateis pacientas, pamatys kliniką, gal jam
                  patiks ir pasiliks jau rimtiems gydymams.
                </Paragraph>
              </Block>

              <Block>
                <H3>Kas tas...svarbiausiasis</H3>
                <Paragraph>
                  Kad ir kaip klinikos renkasi didinti pacientų srautus, visgi
                  dažniausias komandos narys, kuriam tenka priimti naujus
                  pacientus yra burnos higienistas.
                  <br></br> <br></br>
                  Burnos higienistas būna tas komandos narys, kuris privalo
                  matyti plačiai atlikdamas burnos higienos paslaugą. Matyti
                  plačiai, reiškia - pastebėti karjesus, suprasti
                  periodontologinius uždegimus, žinoti kaip prisukti
                  atsilaisvinusią gijimo galvute reikalui esant, rekomenduoti
                  pacientui įsisriegti implantą į jau seniausiai netektą danties
                  vietą ir t.t.
                  <br></br> <br></br>
                  Būtent šis komandos narys generuoja daugiausiai naujų pacientų
                  savo kolegoms gretimam kabinete
                </Paragraph>
              </Block>
            </>
          ) : null}

          {articleId == '3' ? (
            <>
              <Block>
                <H3>“Išleiskite daugiau - uždirbsite daugiau”</H3>
                <Paragraph>
                  O kas jeigu Jums pasakyčiau: “Išleiskite daugiau - uždirbsite
                  daugiau...”? Iš tiesų taip ir yra, kuomet išleidžiate pinigų
                  sumą sukurdami naują darbo vietą klinikoje. Pakalbėkime apie
                  odontologijos klinikos darbuotojo poziciją, kurią vadinu -
                  gydymo koordinatorius.
                  <br></br> <br></br>
                  Nesvarbu kokio dydžio Jūsų klinika, ar tai dviejų
                  odontologinių kėdžių, ar penkiolikos, ši pozicija tinka visoms
                  klinikoms. Tik žinoma, reikia pirmiausia suprasti kodėl Jums
                  jos reikia ir kokią naudą tai duoda.
                </Paragraph>
              </Block>
              <Block>
                <H3>Kas tas...gydymo koordinatorius...?</H3>
                <Paragraph>
                  Paprastai odontologijos klinikos komandoje vyrauja įprasta
                  darbuotojų struktūra: gydytojas odontologas, asistentas,
                  registratūros darbuotojas, dar kartais klinikos vadovas. Kaip
                  ir minėjau, nesvarbu kokio dydžio Jūsų klinika, gydymo
                  koordinatoriui turėtų atsirasti vietos kiekvienoje jų.
                  <br></br> <br></br>
                  Gydymo koordinatorius, tai komandos narys, kuris koordinuoja
                  gydymo eigą: aptaria sudarytą gydymo planą su pacientais,
                  paskiria ir suderina su pacientu jam reikalingus vizitus pas
                  visus gydytojus specialistus, aptaria, jei reikia, finansavimo
                  galimybes, stebi paciento atšauktus vizitus ir jei reikia
                  perskiria naujus, kad nesusidarytų nereikalingų tarpų tarp
                  specialistų vizitų, rūpinasi paciento sveikatos būkle po
                  sudėtingų procedūrų ir t.t. ir pan.
                </Paragraph>
              </Block>
              <Block>
                <H3>Penki gydymo koordinatoriaus žingsniai</H3>
                <Paragraph>
                  Atkreipiant dėmesį į svarbiausias gydymo koordinatoriaus
                  funkcijas, išskiriami šie penki žingsniai:
                  <br></br> <br></br>
                  01 - KLAUSIMAI. <br></br> <br></br> Pacientai, jausdami baimę
                  ir stresą, neužduoda gydytojui odontologui klausimų, kurie
                  pacientui tikrai aktualūs. Gydymo koordinatorius turi galimybę
                  tokius klausimus išklausyti ir ramiai juos aptarti su
                  pacientu.
                  <br></br> <br></br>
                  02 - ŽMONIŲ KALBA. <br></br> <br></br> Dažnai gydytojai
                  odontologai, koonsultuodami pacientą, sudarinėjamą gydymo
                  planą aptaria su pacientu tik gydytojui suprantama kalba. Joje
                  būna daug medicinės terminologijos, kuri sunkiai suprantama
                  pacientui. Gydymo koordinatorius, pacientui sudarytą gydymo
                  planą aptaria “žmonių kalba”.
                  <br></br> <br></br>
                  03 - FINANSAI. <br></br> <br></br>Didžioji dalis gydytojų
                  nemėgsta kalbėti apie kainas su pacientais. Gydytojai,
                  turėdami savo komandoje gydymo koordinatorių gali pamiršti
                  paslaugų kainas, jiems nebereikia jų pristatyti pacientams, už
                  juos tai padaro gydymo koordinatoriai. Tokiu būdu gydytojais
                  išlieka orūs prieš pacientą nedarydami “turgaus” odontologo
                  kėdėje.
                  <br></br> <br></br>
                  04 - RŪPESTIS. <br></br> <br></br> Rūpestis ir paslaugumas
                  tinka visiems pacientams, visi pacientai nori aukšto lygio
                  aptarnavimo. Tą gali suteikti gydymo koordinatorius
                  pateikdamas rekomendacijas atliktoms, ar planuojamoms
                  procedūroms, siųsti sudarytą gydymo planą elektroninu paštu
                  pacientui, pasiskambinti po dienos/kitos, pasiteirauti, ar
                  viskas pacientui suprantama.
                  <br></br> <br></br>
                  05 - TIKSLAS. <br></br> <br></br> Svarbiausia gydymo
                  koordinatoriaus užduotis ir tikslas - pacientui po
                  konsultacijos paskirti antrą vizito datą, kitaip tariant,
                  pradėti gydymą numatytą gydymo plane. Antro vizito kontrolė,
                  ar pacientas jį turi, ar ne, po konsultacijos labai svarbi,
                  deja, bet būtent šią vietą dažnai pameta gydytojai odontologai
                  ir jų konsultacija nueina veltui.
                </Paragraph>
              </Block>

              <Block>
                <H3>
                  Negali būti gydytojas odontologas ir gydymo koordinatorius
                  “viename”{' '}
                </H3>
                <Paragraph>
                  Visus aukščiau išvardintus darbus žinoma gali atlikti ir pats
                  gydytojas, ar registratūros darbuotojas. Labai dažnai gydymo
                  koordinatoriaus pozicijos darbai priskiriami registratūros
                  darbuotojui, tačiau jei taip nutinka, svarbu išlaikyti balansą
                  tarp dviejų darbo vietų ir nenukrypti per daug į vieną, ar
                  kitą, kad būtų atlikti visi numatyti darbai.
                  <br></br> <br></br>
                  Kalbant apie gydytoją odontologą, kuris atlieka gydymo
                  koordinatoriaus pozicijos darbus, čia jau kita ir sakyčiau
                  skausmingesnė tema. Būtent šios dvi pozicijos negali būti
                  sutapatinamos ir atliekamos vienu metu. Paaiškinsiu kodėl.
                  <br></br> <br></br>
                  Viskas remiasi į laiką. Gydytojas odontologas negali skirti
                  tiek daug papildomo laiko pacientui. Kita kalba, jei klinika
                  įprastą konsultacijos laiką turi beveik dvi valandas ir jos
                  kaina ne mažiau 300,00eur.
                </Paragraph>
              </Block>

              <Block>
                <H3>
                  1% gydymo koordinatoriui = 3120,00 eur papildomų pajamų
                  gydytojui
                </H3>
                <Paragraph>
                  Darbo užmokesčio formules, motyvacinius paketus galima taikyti
                  įvarius, skirtingai kiekvienoje klinikoje. Įprastai,
                  pardavimuose dirbantys darbuotojai, o gydymo koordinatorius
                  yra ne kas kitas, kaip specializuotų paslaugų pardavimus
                  atliekantis darbuotojas, gauna procentinį atlygį nuo
                  pardavimų.
                  <br></br> <br></br>
                  Jeigu Jūs turite 20 gydymo planų per mėnesį ir kiekvieno
                  gydymo plano suma vidutiniškai sudaro apie 3500,00eur,
                  skirdami 1% atlygį gydymo koordiantoriui nuo sumokėtų/gautų
                  pajamų iš kiekvieno sudaryto gydymo plano, Jūs galėsite
                  generuoti papildomai 3120,00eur kas mėnesį.
                  <br></br> <br></br>
                  Ir svarbiausia - taupysite konsultacijos laiką, nekalbėsite
                  apie kainas, Jūsų pacientai turės visą jiems reikalingą
                  informaciją, jausis gavę aukščiausio lygio profesionalią
                  paslaugą ir turės antrą paskirtą vizitą tęsti gydymą.
                </Paragraph>
              </Block>
            </>
          ) : null}
        </ArticleContent>
      </Container>
    </Wrapper>
  );
};

ArticlePage.propTypes = {
  type: PropTypes.string,
  className: PropTypes.string,
  label: PropTypes.string,
  onClick: PropTypes.func,
  disabled: PropTypes.bool,
  icon: PropTypes.string,
};

ArticlePage.defaultProps = {
  type: 'button',
  disabled: false,
};
const Wrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  background: #0f0f0f;
  padding: 25vh 7rem 100px;

  @media ${mobile} {
    padding: 25vh 24px;
  }
`;
const Container = styled.div`
  max-width: 1000px;
  margin: 0 auto;
`;
const ArticleTitle = styled.div`
  width: 100%;
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;

  span {
    color: #fff;
    opacity: 0.6;
    text-transform: uppercase;
    font-size: 12px;
    font-weight: 400;
    letter-spacing: 2px;
    margin: 0 0 24px;
  }
  @media ${mobile} {
    span {
      font-size: 10px;
    }
  }
`;
const Block = styled.div`
  width: 100%;
  margin: 0 0 4rem;
`;
const ArticleDate = styled.div`
  width: 100%;
  color: #fff;
  opacity: 0.6;
  letter-spacing: 2px;
  font-size: 18px;
  font-weight: 300;
  margin: 24px 0 0;
  @media ${mobile} {
    font-size: 16px;
  }
`;
const ArticleImageWrapper = styled.div`
  width: 100%;
  margin: 4rem 0;
`;
const ArticleImage = styled.img`
  width: 100%;
`;
const ArticleContent = styled.div`
  width: 100%;
  max-width: 800px;
  margin: 0 auto;
`;
