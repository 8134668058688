import React, { useEffect } from "react";
import styled from "styled-components";
import PropTypes from "prop-types";
import { images } from "../../utils/images";
import { mobile, big } from "../../styles/breakpoints";
import { icons } from "../../utils/icons";
import { useParallax } from "../../lib/useParallax";
import { motion, useAnimation } from "framer-motion";
import { useInView } from "react-intersection-observer";
import { Link } from "react-router-dom";

export const FooterBox = ({ icon, label, secondaryLabel, url }) => {
  const { secondaryX, secondaryY, onMouseMoveHandler, onMouseLeaveHandler } =
    useParallax();

  const control = useAnimation();
  const [inView] = useInView();

  useEffect(() => {
    if (inView) {
      control.start("visible");
    } else {
      control.start("hidden");
    }
  }, [control, inView]);

  const textMotion = {
    rest: {
      y: 0,
      transition: {
        duration: 0.6,
        type: "tween",
        ease: "easeIn",
      },
    },
    hover: {
      y: 110,
      transition: {
        duration: 0.8,
        type: "tween",
        ease: "easeOut",
      },
    },
  };

  const textMotion2 = {
    rest: {
      y: 110,
      transition: {
        duration: 0.6,
        type: "tween",
        ease: "easeIn",
      },
    },
    hover: {
      y: 0,
      transition: {
        duration: 0.8,
        type: "tween",
        ease: "easeOut",
      },
    },
  };

  const overlayMotion = {
    rest: {
      opacity: 1,
      transition: {
        duration: 0.6,
        type: "tween",
        ease: "easeIn",
      },
    },
    hover: {
      opacity: 0,
      transition: {
        duration: 0.6,
        type: "tween",
        ease: "easeOut",
      },
    },
  };

  return (
    <Wrapper
      as={motion.a}
      onMouseMove={onMouseMoveHandler}
      onMouseLeave={onMouseLeaveHandler}
      initial="rest"
      whileHover="hover"
      animate="rest"
      href={url}
    >
      <BoxOverlay variants={overlayMotion} />
      <BoxBackgound
        as={motion.img}
        style={{
          x: secondaryX,
          y: secondaryY,
        }}
        alt=""
        src={images.bg2}
      ></BoxBackgound>
      <TextBoxWrapper>
        <TextIcon src={icon} />
        <WrapText>
          <Text variants={textMotion}>{label}</Text>
          <Text variants={textMotion2}>{secondaryLabel}</Text>
          <BoxIcon src={icons.rightarrowcircle} />
        </WrapText>
      </TextBoxWrapper>
    </Wrapper>
  );
};

FooterBox.propTypes = {
  type: PropTypes.string,
  className: PropTypes.string,
  label: PropTypes.string,
  onClick: PropTypes.func,
  disabled: PropTypes.bool,
  icon: PropTypes.string,
};

FooterBox.defaultProps = {
  type: "button",
  disabled: false,
};
const WrapText = styled.div`
  width: 100%;
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;
const TextIcon = styled.img`
  height: 2rem;
  position: relative;
  z-index: 5;
  @media ${mobile} {
    height: 1.5rem;
  }
`;
const BoxIcon = styled.img`
  height: 1rem;
  position: relative;
  z-index: 5;
`;
const BoxOverlay = styled(motion.div)`
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  z-index: 1;
  width: 100%;
  height: 100%;
  background-color: #0f0f0f;
`;

const TextBoxWrapper = styled.div`
  position: absolute;
  bottom: 2rem;
  left: 0;
  display: flex;
  z-index: 5;
  width: 100%;
  height: 110px;
  overflow: hidden;
  padding: 0 2.5rem;
  align-items: baseline;
  justify-content: space-between;
  flex-direction: column;
  @media ${mobile} {
    height: 68px;
  }
`;
const Text = styled(motion.span)`
  color: #fff;
  font-weight: 400;
  font-family: "Raleway", sans-serif;
  font-size: 1.25rem;
  line-height: 1.4;
  position: relative;
  max-width: 90%;
  &:nth-child(2) {
    position: absolute;
  }
  @media ${mobile} {
    max-width: 100%;
    font-size: 1rem;
  }
  @media ${big} {
    font-size: 1.5rem;
  }
`;
const BoxBackgound = styled.img`
  margin-left: -5%;
  width: 140%;
  height: 140%;
  object-fit: cover;

  @media ${mobile} {
    display: none;
  }
`;
const Wrapper = styled.div`
  width: 100%;
  display: flex;
  flex: 1 0 50%;
  position: relative;
  overflow: hidden;
  height: 450px;

  &:nth-child(1) {
    border-bottom: 1px solid #3c3c3c;
    border-right: 1px solid #3c3c3c;
  }
  &:nth-child(2) {
    border-bottom: 1px solid #3c3c3c;
  }
  &:nth-child(3) {
    border-right: 1px solid #3c3c3c;
  }
  @media ${mobile} {
    flex: 1 0 100%;
    height: 250px;
    border-bottom: 1px solid #3c3c3c;
    &:nth-child(1) {
      border-right: 0px solid #3c3c3c;
      border-bottom: 1px solid #3c3c3c;
    }
    &:nth-child(3) {
      border-right: 0px solid #3c3c3c;
    }
  }
`;
