import bg2 from "../assets/images/bg2.svg";
import about from "../assets/images/about4.png";
import contact from "../assets/images/contact.png";
import article1 from "../assets/images/article1.png";
import article2 from "../assets/images/article2.png";
import article3 from "../assets/images/article3.png";

export const images = {
  bg2,
  about,
  contact,
  article1,
  article2,
  article3,
};
