import { useEffect } from "react";
import styled from "styled-components";
import { icons } from "../../utils/icons";
import { mobile, big } from "../../styles/breakpoints";
import { H2, SmallButton } from "../../components";
import { images } from "../../utils/images";
import { Swiper, SwiperSlide } from "swiper/react";
import SwiperCore, { Navigation, Scrollbar, EffectFade } from "swiper";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "swiper/css/scrollbar";
import { Link } from "react-router-dom";
import { motion, useAnimation } from "framer-motion";
import { useInView } from "react-intersection-observer";
import { useMediaQuery } from "react-responsive";
import { textTransition } from "../../animations/textTransition";

SwiperCore.use([Navigation, Scrollbar, EffectFade]);

const params = {
  loop: true,
  navigation: {
    nextEl: ".arrow-right",
    prevEl: ".arrow-left",
  },

  breakpoints: {
    320: {
      slidesPerView: 1,
      spaceBetween: 40,
    },
    1023: {
      slidesPerView: 3,
      spaceBetween: 40,
    },
    1680: {
      slidesPerView: 3,
      spaceBetween: 40,
    },
    2560: {
      slidesPerView: 3,
      spaceBetween: 40,
    },
  },
};

export const Articles = () => {
  const control = useAnimation();
  const [ref, inView] = useInView();

  useEffect(() => {
    if (inView) {
      control.start("visible");
    } else {
      control.start("hidden");
    }
  }, [control, inView]);

  const articles_list = [
    {
      id: 1,
      title: "Kodėl Jūsų Pacientai Atšaukia Vizitus?",
      date: "Rugsėjo 28",
      image: images.article1,
      url: "article/1",
    },
    {
      id: 2,
      title: "Galbūt Tai Svarbiausias Jūsų Klinikos Komandos Narys?",
      date: "Rugsėjo 23",
      image: images.article2,
      url: "article/2",
    },
    {
      id: 3,
      title:
        "Kaip Dirbant Mažiau Uždirbti daugiau? Kas Tas Gydymo Koordinatorius?",
      date: "Liepos 6",
      image: images.article3,
      url: "article/3",
    },
  ];

  const isMobile = useMediaQuery({ query: mobile });

  return (
    <Wrapper className="services-swiper">
      <Container>
        <TitleWrapper>
          <H2
            as={motion.h2}
            ref={ref}
            variants={textTransition}
            initial="hidden"
            animate={control}
          >
            Straipsniai
          </H2>
          <ButtonsWrapper>
            <ButtonNavigation className="arrow-left">
              <ButtonNavIcon src={icons.arrow_left} />
            </ButtonNavigation>
            <ButtonNavigation
              margin={isMobile ? "0 0 0 0.75rem" : "0 0 0 1rem"}
              className="arrow-right"
            >
              <ButtonNavIcon src={icons.arrow_right} />
            </ButtonNavigation>
          </ButtonsWrapper>
        </TitleWrapper>
      </Container>
      <InnerWrapper>
        <Swiper {...params}>
          {articles_list.map((article, index) => (
            <SwiperSlide key={article.title}>
              <Article>
                <Link to={article.url}>
                  <ArticleImageWrapper>
                    <ArticleImage src={article.image} />
                  </ArticleImageWrapper>
                  <ArticleInfoWrapper>
                    <Date>{article.date}</Date>
                    <Title>{article.title}</Title>
                    <ButtonWrapper>
                      <SmallButton
                        label="Skaityti daugiau"
                        icon={icons.rightarrowcircle}
                      ></SmallButton>
                    </ButtonWrapper>
                  </ArticleInfoWrapper>
                </Link>
              </Article>
            </SwiperSlide>
          ))}
        </Swiper>
      </InnerWrapper>
    </Wrapper>
  );
};
const ArticleImage = styled.img`
  width: 100%;
  height: 100%;
  transform: scale(1);
  object-fit: cover;
  transition: 0.3s all;

  @media ${mobile} {
  }
`;
const ArticleInfoWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  transition: 0.3s all;

  @media ${mobile} {
  }
`;
const ArticleImageWrapper = styled.div`
  width: 100%;
  height: 350px;
  overflow: hidden;

  @media ${mobile} {
  }
`;
const ButtonWrapper = styled.div`
  width: 100%;
  transition: 0.3s all;
  @media ${mobile} {
  }
`;
const Article = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  a {
    text-decoration: none;
  }
  &:hover {
    ${ArticleInfoWrapper} {
      padding-left: 20px;
      transition: 0.3s all;
    }
    ${ArticleImage} {
      transform: scale(1.1);
      transition: 0.3s all;
    }
  }
  @media ${mobile} {
  }
`;

const Title = styled.span`
  font-size: 24px;
  font-weight: 300;
  color: #fff;
  line-height: 1.4;
  margin: 20px 0 30px;
  @media ${mobile} {
  }
  @media ${big} {
    font-size: 30px;
  }
`;
const Date = styled.span`
  font-size: 18px;
  font-weight: 400;
  color: #bbb;
  margin: 40px 0 0;

  @media ${mobile} {
  }
  @media ${big} {
    font-size: 22px;
  }
`;
const Wrapper = styled.div`
  width: 100%;
  padding: 8.25rem 5%;
  position: relative;
  background: #0f0f0f;
  border-top: 1px solid #3c3c3c;
  @media ${mobile} {
    padding: 4rem 32px;
  }
`;
const Container = styled.div`
  width: 100%;
  padding: 0;
  position: relative;
  @media ${mobile} {
  }
`;
const InnerWrapper = styled.div`
  width: 100%;
  position: relative;
`;
const TitleWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 0 0 0rem;
  padding: 0 0 70px;
  @media ${mobile} {
    padding: 0 0 3rem;
  }
`;
const ButtonsWrapper = styled.div`
  display: flex;
`;
const ButtonNavIcon = styled.img`
  height: 14px;
  opacity: 0.6;
`;
const ButtonNavigation = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 56px;
  width: 56px;
  border: 1px solid rgb(185 185 185 / 20%);
  border-radius: 20rem;
  margin: ${(props) => props.margin || "0"};
  transition: 0.2s all;
  @media ${mobile} {
    height: 48px;
    width: 48px;
  }
  &.swiper-button-disabled {
    opacity: 0.5;
  }
  &:hover {
    border: 1px solid #fff;
    cursor: pointer;
    transition: 0.2s all;
  }
  ${ButtonNavIcon} {
    opacity: 1;
    transition: 0.2s all;
  }
`;
